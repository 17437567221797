<template>
  <div
    v-if="isQtyDetail && item[columnName].planQty != 0"
    :class="['qty-cell qty-detail ' + item[columnName].status]"
    @click="clickEvent(item)"
  >
    <div class="plan">{{ item[columnName].planQty }}</div>
    <div class="actual">({{ item[columnName].actualQty }})</div>
  </div>
  <div v-else :class="['qty-cell ' + item[columnName].status]">
    <div class="plan">{{ item[columnName].planQty }}</div>
    <div class="actual">({{ item[columnName].actualQty }})</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
    columnName: {
      type: String,
      default: "",
      required: false,
    },
  },
  created() {},
  methods: {
    clickEvent(item) {
      this.$emit(
        "clickEvent",
        item[this.columnName],
        item[this.columnName].name,
        item.supplierCode,
        item.delivery
      );
    },
  },
  computed: {
    isQtyDetail() {
      return this.item.isQtyDetail && this.columnName != "sum";
    },
  },
};
</script>

<style lang="scss" scoped>
.qty-cell {
  text-align: center;
  height: 100%;
  //border-top: 1px solid $border-color;
  //border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color !important;
  border-left: 1px solid $border-color !important;
  &.diff {
    background-color: $color-diff !important;
  }
  &.warning {
    background-color: $color-warning !important;
  }
  &.error {
    background: $color-error !important;
  }
}
.qty-detail {
  $detail-color: rgb(137, 137, 238);
  text-align: center;
  width: 100%;
  color: blue;
  text-decoration: underline $detail-color 2px;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    background-color: #afcfe4;
    outline: dashed $detail-color 1px;
    &.diff {
      outline: dashed $detail-color 2px;
      background-color: $color-diff-hover !important;
    }
    &.warning {
      outline: dashed $detail-color 2px;
      background-color: $color-warning-hover !important;
    }
    &.error {
      outline: dashed $detail-color 2px;
      background: $color-error-hover !important;
    }
  }
}
</style>
