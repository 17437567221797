<template>
  <div>
    <AppBar />
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <h2 id="lbl-screen-name">進捗確認</h2>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col sm="3">
          <v-row>
            <v-col sm="4">
              <div class="search-label">営業所<span class="require">*</span></div>
            </v-col>
            <v-col sm="8">
              <v-select
                id="lst-sales-office"
                class="list-single"
                v-model="salesOfficeSelected"
                :items="salesOfficeList"
                :single-line="true"
                @change="changeSalesOffice"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="2" id="delivery-date-column">
          <v-row>
            <v-col sm="5">
              <div class="search-label">搬入日付<span class="require">*</span></div>
            </v-col>
            <v-col sm="7">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    id="txt-delivery-date"
                    v-model="deliveryDateCal"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :single-line="true"
                    class="txt-single"
                  ></v-text-field>
                </template>
                <v-date-picker
                  id="calendar-delivery-date"
                  v-model="deliveryDateStr"
                  @input="dateMenu = false"
                  @change="changeDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="6">
          <v-row>
            <v-col sm="3">
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate">＜</v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate">＞</v-btn>
              <v-btn id="btn-today" class="day-ctrl-now-btn" @click="nowDateReset">当日</v-btn>
            </v-col>
            <v-col sm="8">
              <v-row>
                <v-col sm="7" id="radio-area">
                  <div>
                    <v-radio-group
                      id="rdo-search-section"
                      v-model="searchSelected"
                      row
                      @change="changeSearchSelect"
                    >
                      <template v-slot:label>
                        <div id="search-label" style="font-size: large">検索区分</div>
                      </template>
                      <v-radio label="搬入場所" value="2"></v-radio>
                      <v-radio label="取引先" value="1"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
                <v-col sm="5">
                  <v-select
                    id="lst-search-place"
                    class="list-single"
                    v-model="searchPlaceSelected"
                    :items="searchPlaceList"
                    :single-line="true"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="1"> </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="3">
          <v-card id="operationType" outlined>
            <v-card-title id="lbl-operation-switching">表示切替</v-card-title>
            <v-btn-toggle
              v-model="operationTypeSelected"
              color="blue darken-2"
              class="toggle black--text"
            >
              <v-btn id="btn-pack" class="btn" value="1"> 積付 </v-btn>
              <v-btn id="btn-load" class="btn" value="2"> 積込 </v-btn>
            </v-btn-toggle>
          </v-card>
        </v-col>
        <v-col sm="3">
          <div id="access-group">
            <v-card-title> </v-card-title>
            <v-row>
              <v-col
                ><v-btn id="btn-search" class="api-btn" @click="clickSearch(false)"
                  >検索</v-btn
                ></v-col
              >
              <!--<v-col
                ><v-btn id="btn-diff-search" class="api-btn" @click="clickDiffSearch(false)"
                  >差分あり検索</v-btn
                ></v-col
              >-->
              <v-col><v-btn id="btn-clear" class="api-btn" @click="clear">クリア</v-btn></v-col>
            </v-row>
          </div>
        </v-col>
        <v-col sm="2"></v-col>
        <v-col sm="4">
          <div id="table-guide">
            <v-row>
              <v-col sm="4" class="guide-space" id="lbl-namber-of-sheets">
                計画枚数
                <br />
                (実績枚数)
              </v-col>
              <v-col sm="8" id="guide-color" class="guide-space">
                <div id="lbl-warning" class="guide-error">遅延</div>
                <div id="lbl-delay" class="guide-warning">警告</div>
                <div id="lbl-diff" class="guide-diff">差分あり</div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="time-table"
        fixed-header
        :headers="headerItems"
        :items="progressList"
        disable-filtering
        disable-pagination
        disable-sort
        :hide-default-footer="true"
        height="600px"
      >
        <template v-slot:[`item.delivery`]="{ item }">
          <button
            value="詳細"
            class="detail-colmun"
            @click="clickDeliveryPlaceDetail(item)"
            v-if="item.isDeliveryDetail"
          >
            詳細
          </button>
          <div v-else>
            {{ item.delivery }}
          </div>
        </template>
        <template v-slot:[`item.supplier`]="{ item }">
          <button
            value="詳細"
            class="detail-colmun"
            @click="clickSupplierDetail(item)"
            v-if="item.isSupplierDetail"
          >
            詳細
          </button>
          <div v-else>
            {{ item.supplier }}
          </div>
        </template>
        <template v-slot:[`item.col00`]="{ item }">
          <QtyCell :item="item" columnName="col00" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col01`]="{ item }">
          <QtyCell :item="item" columnName="col01" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col02`]="{ item }">
          <QtyCell :item="item" columnName="col02" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col03`]="{ item }">
          <QtyCell :item="item" columnName="col03" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col04`]="{ item }">
          <QtyCell :item="item" columnName="col04" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col05`]="{ item }">
          <QtyCell :item="item" columnName="col05" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col06`]="{ item }">
          <QtyCell :item="item" columnName="col06" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col07`]="{ item }">
          <QtyCell :item="item" columnName="col07" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col08`]="{ item }">
          <QtyCell :item="item" columnName="col08" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col09`]="{ item }">
          <QtyCell :item="item" columnName="col09" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col10`]="{ item }">
          <QtyCell :item="item" columnName="col10" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col11`]="{ item }">
          <QtyCell :item="item" columnName="col11" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col12`]="{ item }">
          <QtyCell :item="item" columnName="col12" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col13`]="{ item }">
          <QtyCell :item="item" columnName="col13" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col14`]="{ item }">
          <QtyCell :item="item" columnName="col14" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col15`]="{ item }">
          <QtyCell :item="item" columnName="col15" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col16`]="{ item }">
          <QtyCell :item="item" columnName="col16" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col17`]="{ item }">
          <QtyCell :item="item" columnName="col17" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col18`]="{ item }">
          <QtyCell :item="item" columnName="col18" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col19`]="{ item }">
          <QtyCell :item="item" columnName="col19" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col20`]="{ item }">
          <QtyCell :item="item" columnName="col20" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col21`]="{ item }">
          <QtyCell :item="item" columnName="col21" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col22`]="{ item }">
          <QtyCell :item="item" columnName="col22" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col23`]="{ item }">
          <QtyCell :item="item" columnName="col23" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.col24`]="{ item }">
          <QtyCell :item="item" columnName="col24" @clickEvent="openDetailDialog" />
        </template>
        <template v-slot:[`item.sum`]="{ item }">
          <QtyCell :item="item" columnName="sum" />
        </template>
      </v-data-table>
    </v-container>
    <!-- 進捗情報詳細モーダル-->
    <v-dialog v-model="isOpenDetailDialog">
      <ProgressDetail :isOpenDetailDialog.sync="isOpenDetailDialog" :entity="detailDialogData" />
    </v-dialog>
    <!--OKダイアログ-->
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
    />
  </div>
</template>

<script>
import SimpleDialog from "@/components/SimpleDialog";
import AppBar from "../components/AppBar.vue";
import QtyCell from "../components/QtyCell.vue";
import ProgressDetail from "./ProgressDetail";
import Loading from "@/components/loading";
import { dateTimeHelper } from "../assets/scripts/js/DateTimeHelper";
import { appConfig } from "../assets/scripts/js/AppConfig";
import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { messsageUtil } from "../assets/scripts/js/MesssageUtil";

export default {
  name: "progress_summary",
  components: {
    AppBar,
    QtyCell,
    SimpleDialog,
    ProgressDetail,
    Loading,
  },
  data: () => ({
    loadingCounter: 0,
    isOpenDetailDialog: false,
    salesOfficeList: [],
    salesOfficeSelected: null,
    deliveryDateStr: null,
    dateMenu: false,
    searchSelected: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    deliveryPlaceList: [{ text: "ALL", value: "all" }],
    supplierList: [{ text: "ALL", value: "all" }],
    searchPlaceSelected: "all",
    operationTypeSelected: "",
    diffSearchSelected: "0",
    nowStatus: "",
    /**
     * 警告灯の連続エラー数です。
     */
    warningLightErrCnt: 0,
    /**
     * 警告灯の存在チェックです。
     */
    isWarningLight: true,
    /**
     * 更新間隔です。
     */
    autoRefreshTime: 5 * 60 * 1000,
    headerItems: [
      { text: "搬入場所", value: "delivery", width: "12rem", align: "center" },
      { text: "取引先", value: "supplier", width: "12rem", align: "center" },
      { text: "0:00", value: "col00", align: "center" },
      { text: "1:00", value: "col01", align: "center" },
      { text: "2:00", value: "col02", align: "center" },
      { text: "3:00", value: "col03", align: "center" },
      { text: "4:00", value: "col04", align: "center" },
      { text: "5:00", value: "col05", align: "center" },
      { text: "6:00", value: "col06", align: "center" },
      { text: "7:00", value: "col07", align: "center" },
      { text: "8:00", value: "col08", align: "center" },
      { text: "9:00", value: "col09", align: "center" },
      { text: "10:00", value: "col10", align: "center" },
      { text: "11:00", value: "col11", align: "center" },
      { text: "12:00", value: "col12", align: "center" },
      { text: "13:00", value: "col13", align: "center" },
      { text: "14:00", value: "col14", align: "center" },
      { text: "15:00", value: "col15", align: "center" },
      { text: "16:00", value: "col16", align: "center" },
      { text: "17:00", value: "col17", align: "center" },
      { text: "18:00", value: "col18", align: "center" },
      { text: "19:00", value: "col19", align: "center" },
      { text: "20:00", value: "col20", align: "center" },
      { text: "21:00", value: "col21", align: "center" },
      { text: "22:00", value: "col22", align: "center" },
      { text: "23:00", value: "col23", align: "center" },
      { text: "合計", value: "sum" },
    ],
    detailDialogData: {},
    autoRefreshEventId: null,
    tableData: [],
    progressList: [],
  }),
  methods: {
    /**
     * 初期化イベントです。
     */
    init() {
      console.info("初期化処理-開始-");
      screenAuth.canAccess();
      // 画面の初期値を設定します。
      const salesOfficeCode = sessionStorage.getItem("sales_office_code");
      this.salesOfficeSelected = salesOfficeCode;
      this.nowDateReset();
      this.searchSelected = appConfig.SEARCH_TYPE.deliveryAddress;
      this.operationTypeSelected = appConfig.SUMMARY_OPERATION_TYPE.pack;
      this.warningLightErrCnt = 0;

      // MEMO クレデンシャルのスレッドセーフな取得が出来ないため、
      //      先にクレデンシャルを更新します。
      //this.$httpClient
      //   .getApiGatewayCredentials()
      //  .then((result) => {
      //    console.debug(result);
      //検索区分のリストボックス値
      this.searchPlaceSelected = "all";

      Promise.all([])
        .then(() => {
          // 自動更新を設定します。
          this.getSalesOfficeList();
          this.autoRefreshEventId = setInterval(this.autoRefresh, this.autoRefreshTime);
          this.search(false);
        })
        .catch((ex) => {
          console.error("init error", ex);
        })
        .finally(() => {
          console.info("初期化処理-終了-");
        });
      //  })
      //  .catch();
    },
    /**
     * 拠点マスタ参照APIを呼び出します。（営業所リスト）
     */
    getSalesOfficeList() {
      console.debug("getSalesOfficeList--start--");
      screenAuth.canAccess();
      const config = this.$httpClient.createGetApiRequestConfig();
      // 条件：システム運用会社（日梱拠点）
      config.params.baseType = appConfig.BASE_TYPE.systemOperationCompany;
      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.bases, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            const list = [];
            if (jsonData.resCom.resComCode == "000") {
              // 営業所が選択有無
              var isSelect = false;
              jsonData.resIdv.resBases.forEach((row) => {
                // ログインユーザの営業所ががリストにあるか判定
                if (this.salesOfficeSelected == row.baseCode) {
                  isSelect = true;
                }
                list.push({ text: row.baseNameAlt, value: row.baseCode });
              });
              this.salesOfficeList = list;
              // ログインユーザの営業所がリストに存在しない場合、自動選択なし
              if (!isSelect) {
                this.salesOfficeSelected = jsonData.resIdv.resBases[0].baseCode;
              }
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
            console.debug("getSalesOfficeList--end--");
          });
      });
    },
    /**
     * 搬入先マスタ参照APIを呼び出します。（搬入場所リスト）
     */
    getDeliveryPlaceList() {
      console.debug("getDeliveryPlaceList--start--");
      screenAuth.canAccess();
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.searchType = appConfig.DESTINATIONS_SEARCH_TYPE.progressSummary;
      // 画面の搬入日付は「-」区切りなので、ハイフンを取り除いてyyyyMMddにします。
      const deliveryDate = this.deliveryDateStr.replace(/-/g, "");
      config.params.value01 = deliveryDate;
      config.params.value02 = this.salesOfficeSelected;
      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.destinations, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "ALL", value: "all" }];
              jsonData.resIdv.resDestinations.forEach((row) => {
                list.push({ text: row.deliveryDestAddress, value: row.deliveryDestAddress });
              });
              this.deliveryPlaceList = list;
              //this.changeSearchSelect();
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
            console.debug("getDeliveryPlaceList--end--");
          });
      });
    },
    /**
     * 取引先マスタ参照APIを呼び出します。（取引先リスト）
     */
    getSupplierList() {
      console.debug("getSupplierList--start--");
      screenAuth.canAccess();
      const config = this.$httpClient.createGetApiRequestConfig();
      //条件：取引先区分「メーカー」
      config.params.supplierType = appConfig.SUPPLIER_TYPE.maker;
      config.params.searchType = appConfig.DESTINATIONS_SEARCH_TYPE.progressSummary;
      // 画面の搬入日付は「-」区切りなので、ハイフンを取り除いてyyyyMMddにします。
      const deliveryDate = this.deliveryDateStr.replace(/-/g, "");
      config.params.value01 = deliveryDate;
      config.params.value02 = this.salesOfficeSelected;
      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.suppliers, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "ALL", value: "all" }];
              jsonData.resIdv.resSuppliers.forEach((row) => {
                list.push({ text: row.supplierName, value: row.supplierCode });
              });
              this.supplierList = list;
              //this.changeSearchSelect();
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
            console.debug("getSupplierList--end--");
          });
      });
    },
    /**
     * データテーブルに予定実績をマッピングします。
     */
    setProgressList() {
      // 予定実績をデータテーブル用に構成します。
      this.progressList = this.tableData.map((row) => {
        let result = row;
        result.supplier = row.supplierNameAlt;
        //result.delivery = row.deliveryAddressCode;
        result.delivery = row.deliveryDestAddressCode;

        // 選択されている検索区分と選択肢のAllかそれ以外の違いによる詰め直しを行います。
        if (this.isDeliveryAddressDetailCondition) {
          result.isDeliveryDetail = true;
          result.isSupplierDetail = false;
          result.isQtyDetail = false;
        } else if (this.isSupplierDetailCondition) {
          result.isDeliveryDetail = false;
          result.isSupplierDetail = true;
          result.isQtyDetail = false;
        } else {
          result.isDeliveryDetail = false;
          result.isSupplierDetail = false;
          result.isQtyDetail = true;
        }

        // 時間帯ごとの予定実績はそれぞれの配列下に0～23として詰め直します。
        for (let i = 0; i < 24; i++) {
          let num = ("0" + i).slice(-2);
          let cell = {};
          cell.planQty = row["planQty" + num];
          cell.actualQty = row["actualQty" + num];
          cell.statusValue = row["status" + num];
          cell.name = "col" + num;
          // ステータスの値を元に背景色変更用のCSS名を設定します。
          if (row["status" + num] == "1") {
            cell.status = "diff";
          } else if (row["status" + num] == "2") {
            cell.status = "warning";
          } else if (row["status" + num] == "3") {
            cell.status = "error";
          } else {
            cell.status = "normal";
          }
          result["col" + num] = cell;
        }
        let sumCell = {};
        sumCell.planQty = row["sumPlan"];
        sumCell.actualQty = row["sumActual"];
        sumCell.status = null;
        result.sum = sumCell;
        return result;
      });
    },
    /**
     * 搬入日を－１日します。
     */
    prevDate() {
      let date = new Date(this.deliveryDateStr);
      this.deliveryDateStr = this.dateCalc(date.setDate(date.getDate() - 1));
      this.changeDate();
    },
    /**
     * 搬入日を＋１日します。
     */
    nextDate() {
      let date = new Date(this.deliveryDateStr);
      this.deliveryDateStr = this.dateCalc(date.setDate(date.getDate() + 1));
      this.changeDate();
    },
    /**
     * 搬入日を当日にリセットします。
     */
    nowDateReset() {
      this.deliveryDateStr = this.dateCalc(dateTimeHelper.convertJstDateTime(new Date()));
      this.changeDate();
    },
    /**
     * 与えられた日付から年月日を文字列で返します。
     */
    dateCalc(date) {
      const dt = new Date(date);
      const yyyy = dt.getFullYear();
      const mm = ("0" + (dt.getMonth() + 1)).slice(-2);
      const dd = ("0" + dt.getDate()).slice(-2);
      return yyyy + "-" + mm + "-" + dd;
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    /**
     * 営業所を変更した際のイベントです。
     */
    changeSalesOffice() {
      console.debug("changeSalesOffice");
      this.changeDate();
      /*
      console.debug("搬入場所再取得--start--");
      this.getSalesOfficeList()
        .then(() => {
          console.debug("搬入場所再取得--then--");
        })
        .catch(() => {
          console.debug("搬入場所再取得--catch--");
        })
        .finally(() => {
          console.debug("搬入場所再取得--end--");
          this.changeDate();
        });
        */
    },
    /**
     * 検索区分を変更した際のイベントです。
     */
    changeSearchSelect() {
      console.debug("changeSearchSelect");
      // 表示されているリストに選択値が存在しなければallをセットします。

      if (
        this.searchPlaceList.findIndex((row) => {
          if (row.value == this.searchPlaceSelected) {
            return true;
          }
        }) < 0
      ) {
        this.searchPlaceSelected = "all";
      }

      if (this.searchSelected == appConfig.SEARCH_TYPE.deliveryAddress) {
        console.debug("搬入場所再取得--start--");
        this.getDeliveryPlaceList()
          .then(() => {})
          .catch(() => {})
          .finally(() => {});
      } else {
        console.debug("取引先再取得--start--");
        this.getSupplierList()
          .then(() => {})
          .catch(() => {})
          .finally(() => {});
      }
    },
    /**
     * 検索処理の実体です。
     */
    search(autoRefresh) {
      console.log("search");
      screenAuth.canAccess();
      this.tableData = [];
      const config = this.$httpClient.createGetApiRequestConfig();
      this.loadingCounter++;
      //処理区分 operationType 1:積付、2:積込
      config.params.operationType = this.operationTypeSelected;
      //営業所コード
      config.params.salesOfficeCode = this.salesOfficeSelected;
      //搬入日付
      config.params.deliveryDate = dateTimeHelper.toStringDate(
        "YYYY/MM/DD",
        new Date(this.deliveryDateStr)
      );

      //検索区分
      config.params.searchType = this.searchSelected;
      if (this.searchSelected == appConfig.SEARCH_TYPE.supplier) {
        //取引先コード
        if (this.searchPlaceSelected != "all") {
          config.params.supplierCode = this.searchPlaceSelected;
        }
      } else {
        //搬入場所コード
        if (this.searchPlaceSelected != "all") {
          config.params.deliveryDestAddressCode = this.searchPlaceSelected;
        }
      }
      //差分あり検索
      config.params.diffSearchType = this.diffSearchSelected;
      this.$httpClient
        .secureGet(appConfig.API_URL.progressSummary, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          if (jsonData.resCom.resComCode == "000") {
            this.tableData = jsonData.resIdv.resProgressList;

            this.setProgressList();
            this.progressHeaders();
            // 警告灯制御を呼び出します。
            if (this.nowStatus != this.callWarningLightStatus() || !autoRefresh) {
              this.callWarningLight();
            }
          } else {
            this.infoDialog.message = jsonData.resCom.resComMessageId
              ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
              : jsonData.resCom.resComMessage;
            this.infoDialog.title = "結果";
            this.infoDialog.isOpen = true;
          }
        })
        .catch((error) => {
          // MEMO 警告灯のエラーは今後もコンソール出力しておくことをお勧めします。
          // eslint-disable-next-line no-console
          this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;

          console.error("search error", error);
        })
        .finally(() => {
          this.loadingCounter--;
        });
    },
    /**
     * 検索ボタン押下時のイベントです。
     */
    clickSearch() {
      // this.searchPlaceSelected = item.supplierCode;
      this.diffSearchSelected = "0";
      this.search(false);
    },
    /**
     * 差分あり検索ボタン押下時のイベントです。
     */
    clickDiffSearch() {
      // this.searchPlaceSelected = item.supplierCode;
      this.diffSearchSelected = "1";
      this.search(false);
    },
    /**
     * データテーブルヘッダーのステータス色を設定します。
     */
    progressHeaders() {
      console.debug("--progressHeaders--");
      for (let x = 0; x < 24; x++) {
        let status = "normal";
        let num = ("0" + x).slice(-2);
        for (let y = 0; y < this.progressList.length; y++) {
          // console.log(this.progressList[y]);
          const colStatus = this.progressList[y]["col" + num].status;
          if (colStatus == "warning" || colStatus == "diff") {
            status = colStatus;
          } else if (colStatus == "error") {
            status = colStatus;
            break;
          }
        }
        this.headerItems[x + 2].class = status;
      }

      return this.headerItems;
    },
    /**
     * クリアボタンのイベントです。
     */
    clear() {
      console.debug("clear");
      screenAuth.canAccess();
      // 画面の初期値を設定します。
      const salesOfficeCode = sessionStorage.getItem("sales_office_code");
      this.salesOfficeSelected = salesOfficeCode;
      this.nowDateReset();
      this.searchSelected = appConfig.SEARCH_TYPE.deliveryAddress;
      this.searchPlaceSelected = "all";
      this.operationTypeSelected = appConfig.SUMMARY_OPERATION_TYPE.pack;
      this.diffSearchSelected = "0";
      this.changeSearchSelect();
    },
    /**
     * 搬入場所の詳細リンクをクリックしたときのイベントです。
     */
    clickDeliveryPlaceDetail(item) {
      this.searchPlaceSelected = item.supplierCode;
      this.search(false);
    },
    /**
     * 取引先の詳細リンクをクリックしたときのイベントです。
     */
    clickSupplierDetail(item) {
      this.searchPlaceSelected = item.deliveryDestAddressCode;
      this.search(false);
    },
    /**
     * 進捗情報詳細画面を開きます。
     */
    openDetailDialog(item, selectColumnName, targetSupplierCode, targetDelivery) {
      console.info("openDetailDialog");
      screenAuth.canAccess();
      // 詳細画面へ渡す値を設定します。
      this.detailDialogData = item;
      // 選択された列名
      this.detailDialogData.selectColumn = selectColumnName;
      // 営業所
      const salesOfficeObj = this.salesOfficeList.find((element) => {
        if (element.value == this.salesOfficeSelected) {
          return true;
        }
      });
      this.detailDialogData.salesOffice = {
        value: salesOfficeObj.value,
        text: salesOfficeObj.text,
      };
      // 検索種類、選択表示値、選択内部値
      const searchSelectObj = this.searchPlaceList.find((element) => {
        if (element.value == this.searchPlaceSelected) {
          return true;
        }
      });
      this.detailDialogData.searchSelect = {
        type: this.searchSelected,
        value: searchSelectObj.value,
        text: searchSelectObj.text,
      };
      // 搬入日付
      this.detailDialogData.deliveryDateStr = this.deliveryDateStr;
      // クリックされた列によって搬入時間を特定します。
      if (selectColumnName.indexOf("col") >= 0) {
        const column = this.headerItems.find((element) => {
          if (element.value == selectColumnName) {
            return true;
          }
          return false;
        });
        this.detailDialogData.deliveryTime = column.text;
      } else {
        this.detailDialogData.deliveryTime = "00:00";
      }

      this.detailDialogData.selectSalesOfficeCode = salesOfficeObj.value;
      this.detailDialogData.deliveryDestAddressCode = targetDelivery;
      this.detailDialogData.supplierCode = targetSupplierCode;

      this.isOpenDetailDialog = true;
    },
    /**
     * 非同期で警告灯を呼び出します。Loading制御は行いません。
     */
    callWarningLight() {
      // 警告灯フラグがONの場合以外は呼び出しを行わない
      if (sessionStorage.getItem("pt") != "1") {
        return;
      }

      if (this.isWarningLight == false) {
        console.info("連続エラー回数が規定値を越えたため、警告灯呼び出しはスキップされます。");
        return;
      }

      const config = {};
      config.baseURL = appConfig.APP_CONFIG.CALLWARNINGLIGHT_URL;
      config.params = this.getAcopParams2();
      this.$httpClient
        .get("/api/control", config)
        .then(() => {
          this.warningLightErrCnt = 0;
        })
        .catch((error) => {
          console.error("callWarningLight error", error);
          this.warningLightErrCnt++;
          // 警告灯の連続エラーが許容値を越えたら制限解除します。
          if (this.warningLightErrCnt > appConfig.ScreenConst.WarningLightErrCntBorder) {
            this.isWarningLight = false;
          }
        });
    },
    /**
     * 非同期で警告灯が鳴動しているか判断します。Loading制御は行いません。
     */
    callWarningLightStatus() {
      // 警告灯フラグがONの場合以外は呼び出しを行わない
      if (sessionStorage.getItem("pt") != "1") {
        return;
      }

      if (this.isWarningLight == false) {
        return;
      }

      const config = {};
      config.baseURL = appConfig.APP_CONFIG.CALLWARNINGLIGHT_URL;
      // config.params = this.getAcopParams;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .get("/api/control?acop", config)
          .then((result) => {
            this.warningLightErrCnt = 0;
            resolve(result.data);
          })
          .catch((error) => {
            console.error("callWarningLightStatus error", error);
            this.warningLightErrCnt++;
            // 警告灯の連続エラーが許容値を越えたら制限解除します。
            if (this.warningLightErrCnt > appConfig.ScreenConst.WarningLightErrCntBorder) {
              this.isWarningLight = false;
            }
            reject("");
          });
      });
    },
    // 自動更新を行います。
    autoRefresh() {
      console.debug("autoRefresh");
      // TODO 鳴っているときには鳴らさないことで、一定時間で切りたい
      // this.callWarningLightStatus();
      this.clickSearch(true);
    },
    changeDate() {
      console.debug("changeDate");
      const getDeliveryPlaceList = this.getDeliveryPlaceList();
      const getSupplierList = this.getSupplierList();
      Promise.all([getDeliveryPlaceList, getSupplierList])
        .then(() => {
          if (
            this.searchPlaceList.findIndex((row) => {
              if (row.value == this.searchPlaceSelected) {
                return true;
              }
            }) < 0
          ) {
            this.searchPlaceSelected = "all";
          }
        })
        .catch((ex) => {
          console.error("init error", ex);
        })
        .finally(() => {});
      /*
      this.$httpClient
        .secureGet(appConfig.API_URL.suppliers, config)
        .then((response) => {
          //.getApiGatewayCredentials()
          //.then((result) => {
            console.debug(result);
          const processDeliveryPlace = this.getDeliveryPlaceList();
          const processSupplier = this.getSupplierList();
          //検索区分のリストボックス値
          this.searchPlaceSelected = "all";

          Promise.all([processDeliveryPlace, processSupplier])
            .then((result) => {
              console.debug(result);
            })
            .catch((ex) => {
              console.error(ex);
            })
            .finally(() => {
              console.info("初期化処理-終了-");
            });
        })
        .catch();
        */
    },
    /**
     * 警告灯のステータスを取得します。
     */
    getWarningLightStatus() {
      let status = "normal";
      for (let x = 0; x < 24; x++) {
        if (this.headerItems[x + 2].class != "normal") {
          status = this.headerItems[x + 2].class;
          if (status == "error") {
            break;
          }
        }
      }
      return status;
    },
    /**
     * 警告灯のステータスから返却値を決定
     */
    getAcopParams2() {
      const status = this.getWarningLightStatus();
      const alertTime = 60 * 60;
      if (status == "error") {
        this.nowStatus = "20010000";
        return { acop: "20010000", t: alertTime };
      } else if (status == "warning") {
        this.nowStatus = "02010000";
        return { acop: "02010000", t: alertTime };
      } else {
        this.nowStatus = "1";
        return { clear: "1" };
      }
    },
  },
  computed: {
    /**
     * フォーマットした搬入日を返します。
     */
    deliveryDateCal() {
      return this.formatDate(this.deliveryDateStr);
    },
    /**
     * 検索区分横の搬入場所or取引先のリストを返します。
     */
    searchPlaceList() {
      if (this.searchSelected == appConfig.SEARCH_TYPE.supplier) {
        return this.supplierList;
      } else {
        return this.deliveryPlaceList;
      }
    },
    /**
     * 搬入場所が詳細と表示される条件式です。
     */
    isDeliveryAddressDetailCondition() {
      return (
        this.searchSelected == appConfig.SEARCH_TYPE.supplier && this.searchPlaceSelected == "all"
      );
    },
    /**
     * 取引先が詳細と表示される条件式です。
     */
    isSupplierDetailCondition() {
      return (
        this.searchSelected == appConfig.SEARCH_TYPE.deliveryAddress &&
        this.searchPlaceSelected == "all"
      );
    },
    /**
     * 警告灯のステータスを取得します。
     */
    warningLightStatus() {
      let status = "normal";
      for (let x = 0; x < 24; x++) {
        if (this.headerItems[x + 2].class != "normal") {
          status = this.headerItems[x + 2].class;
          if (status == "error") {
            break;
          }
        }
      }
      return status;
    },
    /**
     * 警告灯のステータスから返却値を決定
     */
    getAcopParams() {
      const status = this.warningLightStatus;
      const alertTime = 60 * 60;
      if (status == "error") {
        return { acop: "20010000", t: alertTime };
      } else if (status == "warning") {
        return { acop: "02010000", t: alertTime };
      } else {
        return { clear: "1" };
      }
    },
  },
  mounted() {
    this.init();
  },
  destroyed() {
    // 自動更新イベントを開放します。
    if (this.autoRefreshEventId) {
      clearInterval(this.autoRefreshEventId);
    }
  },
};
</script>

<style lang="scss" scoped>
#sub-bar {
  color: black;
  background: $color-obj-base;
  padding: 0;
  height: 3rem;
  h2 {
    padding: 0.5rem;
  }
}

.date-calendar {
  z-index: 200;
}

.v-btn.v-btn--has-bg {
  background: $color-obj-base;
  &.api-btn {
    width: 7rem;
  }
}

button {
  &.v-btn {
    &.day-ctrl-btn {
      padding: 0;
      width: 2rem;
      min-width: 2.5rem;
    }
    &.day-ctrl-now-btn {
      padding: 0;
      width: 4rem;
      min-width: 4rem;
    }
  }
}

#delivery-date-column {
  min-width: 20rem !important;
}

#operationType {
  width: 15rem;
  height: 5.5rem;
  .v-card__title {
    font-size: 1rem;
    padding: 0 0.2rem;
  }
  .toggle {
    padding: 0.3rem 2rem;
    .btn {
      width: 5rem;
      height: 2.5rem;
    }
  }
}
#access-group {
  width: 17rem;
  height: 6rem;
  padding: 0.3rem 0.5rem;
}

#time-table {
  &.v-data-table--fixed-header ::v-deep {
    th {
      padding: 0.1rem;
      background: #fbe9db;
      height: 1.75rem;
      text-align: center !important;
      // sortが必要であれば以下を有効にしてください。
      //padding-left: 1rem;
      min-width: 4.5rem;
    }
    td {
      padding: 0rem;
      height: 100%;
      width: 100%;
      margin: auto;
    }
  }
}

.v-data-table--fixed-header ::v-deep {
  table {
    border-collapse: collapse !important;
  }
  td {
    height: 3rem !important;
  }
  tr:nth-child(odd) {
    td:not(:nth-child(-n + 2)) {
      background-color: #dde5f0;
      font-size: medium !important;
    }
  }
  th {
    background-color: #fbe9db;
    font-size: medium !important;
    &.diff {
      background-color: $color-diff !important;
    }
    &.warning {
      background-color: $color-warning !important;
    }
    &:nth-child(1),
    &:nth-child(2) {
      position: sticky !important;
      position: -webkit-sticky !important;
      z-index: 21;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top: 1px solid $border-color;
        //border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }
    &:last-child {
      border-right: 1px solid $border-color;
    }
    &:not(:nth-child(-n + 2)) {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top: 1px solid $border-color;
        //border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }
    &:nth-child(1) {
      left: 0;
    }
    &:nth-child(2) {
      left: 12rem;
    }
  }
  td {
    $detail-color: rgb(137, 137, 238);
    button.detail-colmun {
      width: 100%;
      height: 100%;
      display: block !important;
      color: blue;
      text-decoration: underline $detail-color 2px;
      box-sizing: border-box;
      &:hover {
        background-color: #afcfe4;
        //outline: dashed $detail-color 2px;
      }
    }
    &:nth-child(1),
    &:nth-child(2) {
      background: #eef1de;
      position: sticky !important;
      position: -webkit-sticky !important;
      z-index: 20;
      button::before,
      div::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //border-top: 1px solid $border-color;
        //border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }
    &:nth-child(1) {
      left: 0;
      font-size: large;
    }
    &:nth-child(2) {
      left: 12rem;
    }
    &:last-child {
      border-right: 1px solid $border-color;
    }
  }
}

#table-guide {
  margin-top: 4.5rem;
  #lbl-namber-of-sheets {
    width: 100%;
    text-align: center;
  }
  .guide-space {
    padding: 0;
  }
  #guide-color {
    margin-top: 1rem;
    .guide-diff,
    .guide-warning,
    .guide-error {
      display: inline-block;
      width: 6rem;
      text-align: center;
      border: solid 1px;
      margin: 0.2rem 0.5rem;
    }
    .guide-diff {
      background: $color-diff;
    }
    .guide-warning {
      background: $color-warning;
    }
    .guide-error {
      background: $color-error;
    }
  }
}

.list-single ::v-deep {
  div.v-input__slot {
    border-radius: 0.4rem;
  }
}
.list-single ::v-deep,
.list-multi ::v-deep {
  div.v-input__icon {
    background: #95b3d7;
    border-radius: 0.3rem;
  }
}

.search-label {
  width: 100%;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0;
  text-align: right;
  font-size: large;
  color: rgba(0, 0, 0, 0.6);
}

.require {
  font-size: large;
  color: red;
}

#radio-area {
  padding-top: 0;
  padding-bottom: 0.5rem;
}

.list-single ::v-deep,
.txt-single ::v-deep {
  z-index: 50;
  padding-top: 0;
  padding-right: 0;
  font-size: large;
}

.txt-single ::v-deep {
  div.v-select__selection {
    width: 100%;
    text-align: center;
  }
  input {
    text-align: center;
    border-radius: 0.4rem;
  }
  .v-messages__message {
    line-height: 1.75rem;
    font-size: 130%;
  }
}
</style>
